html {
  scroll-behavior: smooth;
}

body {
  background: #151414;
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Inter, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*, ::after, ::before {
  box-sizing: border-box;
}

*,
button,
input {
  font-family: 'Inter', serif;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

html,
#root,
body {
  overflow-x: hidden;
}

/* *** BASIC COMMON CSS *** */
.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.tags {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  padding: 2px 4px;
  color: #FFFFFF88;
}

.activeSidebar {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 2px;
  width: 100%;
  color: white !important;
}

.center {
  transform: translate(-50%, -50%);
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.links {
  color: #f47f57;
}

/* Theme Stuffs */

/* *** SPACINGS *** */
/* Margins */
.m-l--4 {
  margin-left: -4px;
}

.m-l-2 {
  margin-left: 2px;
}

.m-l-4 {
  margin-left: 4px;
}

.m-l-8 {
  margin-left: 8px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-16 {
  margin-left: 16px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-24 {
  margin-left: 24px;
}

.m-l-32 {
  margin-left: 32px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-r-2 {
  margin-right: 2px;
}

.m-r-4 {
  margin-right: 4px;
}

.m-r-8 {
  margin-right: 8px;
}

.m-r-12 {
  margin-right: 12px;
}

.m-r-16 {
  margin-right: 16px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-24 {
  margin-right: 24px;
}

.m-r-32 {
  margin-right: 32px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-t-2 {
  margin-top: 2px;
}

.m-t-4 {
  margin-top: 4px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-24 {
  margin-top: 24px;
}

.m-t-32 {
  margin-top: 32px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-b-2 {
  margin-bottom: 2px;
}

.m-b-4 {
  margin-bottom: 4px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-b-26 {
  margin-bottom: 26px;
}

.m-b-32 {
  margin-bottom: 32px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-80 {
  margin-bottom: 80px;
}

/* Paddings */

.p-l-2 {
  padding-left: 2px;
}

.p-l-4 {
  padding-left: 4px;
}

.p-l-8 {
  padding-left: 8px;
}

.p-l-12 {
  padding-left: 12px;
}

.p-l-16 {
  padding-left: 16px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-24 {
  padding-left: 24px;
}

.p-l-32 {
  padding-left: 32px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-r-2 {
  padding-right: 2px;
}

.p-r-4 {
  padding-right: 4px;
}

.p-r-8 {
  padding-right: 8px;
}

.p-r-12 {
  padding-right: 12px;
}

.p-r-16 {
  padding-right: 16px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-24 {
  padding-right: 24px;
}

.p-r-32 {
  padding-right: 32px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-t-2 {
  padding-top: 2px;
}

.p-t-4 {
  padding-top: 4px;
}

.p-t-8 {
  padding-top: 8px;
}

.p-t-12 {
  padding-top: 12px;
}

.p-t-16 {
  padding-top: 16px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-24 {
  padding-top: 24px;
}

.p-t-32 {
  padding-top: 32px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-b-2 {
  padding-bottom: 2px;
}

.p-b-4 {
  padding-bottom: 4px;
}

.p-b-8 {
  padding-bottom: 8px;
}

.p-b-12 {
  padding-bottom: 12px;
}

.p-b-16 {
  padding-bottom: 16px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-24 {
  padding-bottom: 24px;
}

.p-b-32 {
  padding-bottom: 32px;
}

.p-b-40 {
  padding-bottom: 40px;
}

/* *** MATERIALS *** */
.material-primary {
  position: relative;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(21px);
  border-radius: 6px;
  padding: 32px;
}

.material-primary::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  padding: 1px;
  background: linear-gradient(
    180deg,
    rgba(255, 116, 38, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  z-index: -1;
}

.material-secondary {
  background: linear-gradient(180deg, #48423e 0%, #373030 100%);
  box-shadow: 0 12px 24px -4px rgba(0, 0, 0, 0.12),
  0 16px 20px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 100%;
  padding: 32px;
}

@media (max-width: 600px) {
  .material-primary, .material-secondary {
    padding: 32px 16px;
  }

  .material-primary::before {
  }
}

.material-tertiary {
  background: linear-gradient(38.44deg, #f47f57 23.51%, #fd5656 69.95%);
  border-radius: 6px;
}

.material-quaternary {
  background: linear-gradient(180deg, #2d2d2d 0%, #1c1c1c 100%);
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 32px;
}

.custom-mahadao-container {
  background: linear-gradient(180deg, #48423E 0%, #373030 100%);
  border-radius: 6px;
}

.custom-mahadao-container-header {
  padding: 0 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.custom-mahadao-container-content {
  padding: 24px 32px 32px 32px;
}

@media (max-width: 600px) {
  .custom-mahadao-container {
    border-radius: 0;
  }

  .custom-mahadao-container-header {
    padding: 0 16px;
    /*border-radius: 0;*/
  }

  .custom-mahadao-container-content {
    padding: 24px 16px;
    /*border-radius: 0;*/
  }
}


/* *** SHADOWS *** */
.shadow-sm {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.shadow-md {
  box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.12);
}

.shadow-lg {
  box-shadow: 0 12px 24px -4px rgba(0, 0, 0, 0.12), 0 16px 20px rgba(0, 0, 0, 0.25);
}

.shadow-xlg {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.6);
}

/* *** CORNER SYSTEM *** */
@media (max-width: 600px) {
  .mo-no-corner {
    border-radius: 0 !important;
  }
}


.corner-sm {
  border-radius: 2px;
}

.corner-md {
  border-radius: 4px;
}

.corner-lg-md {
  border-radius: 6px;
}

.corner-lg {
  border-radius: 8px;
}

.corner-xl {
  border-radius: 12px;
}

.corner-xxl {
  border-radius: 16px;
}

/* *** FLEX SYSTEM *** */
@media (max-width: 600px) {
  .mo-single-line-column {
    display: block !important;
  }
}

.single-line {
  display: flex;
  flex-direction: row;
}

.flex1 {
  flex: 1;
}

.single-line-center-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.start-center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.single-line-center-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.single-line-center-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.single-line-center-evenly {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.single-line-center-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.single-line-start-between {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.single-line-baseline-start {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.single-line-baseline-between {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.single-line-baseline-center {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}

/* *** CONTAINER *** */
.custom-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 0 auto;
  padding: 0 0 0 24px;
}

@media (max-width: 600px) {
  .custom-container {
    padding: 0;
  }

  .mo-custom-container {
    padding: 0 16px;
  }

  .border-container {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    border-right: none !important;
    margin-bottom: 14px !important;
    padding-bottom: 28px !important;
  }
}

.border-container {
  border-right: 1px solid rgba(255, 255, 255, 0.08);
}

/* *** DIVIDERS *** */
.bottom-divider {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.top-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.left-divider {
  border-left: 1px solid rgba(255, 255, 255, 0.08);
}

.right-divider {
  border-right: 1px solid rgba(255, 255, 255, 0.08);
}

/* *** ALIGNMENT *** */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* *** Custom *** */
.p-6 {
  padding: 6px !important;
}

/* width */
::-webkit-scrollbar {
  -webkit-appearance: none !important;
  width: 2px;
  height: 2px;
}

@media (max-width: 800px) {
  ::-webkit-scrollbar {
    width: unset;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FFFFFF20 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent !important;
}
