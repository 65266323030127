$font-family-heading: 'Syne', sans-serif;
$font-family-body: 'Inter', sans-serif;
$max-width: 1200px;

.typo-syne-h1, .typo-syne-h2, .typo-syne-h3, .typo-syne-h4, .typo-syne-h5 {
  font-family: $font-family-heading; }

.typo-syne-h1 {
  font-size: 42px;
  font-weight: 700; }


.typo-syne-h2 {
  font-size: 32px;
  font-weight: 700; }

.typo-syne-h3 {
  font-size: 24px; }

.typo-syne-h4 {
  font-size: 18px; }

.typo-syne-h5 {
  font-size: 16px; }

.max-content {
  width: max-content; }

#governance-landing {

  .header {
    font-family: $font-family-heading;
    text-align: center;
    color: #FFFFFF88;
    font-weight: bold;
    font-size: 24px;
    margin: 162px auto 40px auto;

    width: 100%;
    max-width: 1200px;
    padding: 0 0 0 24px;

    @media (max-width: 600px) {
      padding: 0 20px;
      margin-top: 100px; } }

  .basic-line {
    font-family: $font-family-heading;
    margin-bottom: 8px; }

  .main-head-line {
    font-family: $font-family-heading;
    margin-bottom: 8px;
    color: white;
    font-size: 42px; }

  .header-explain {
    font-family: $font-family-heading;
    max-width: 1000px;
    margin: 0 auto 40px auto;
    font-size: 18px; }

  .call-to-action {
    font-family: $font-family-body;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    background: linear-gradient(38.44deg, #F47F57 15.81%, #FD5656 87.57%);
    border-radius: 6px;
    margin: auto;
    padding: 12px 16px;
    display: block;
    text-align: center;
    cursor: pointer; }

  .background-1 {
    height: 240px;
    width: 100%;
    margin-bottom: 80px;
    background: url("../image/bg1.svg") center; }

  .future-of-mahadao {
    padding-top: 1px;

    .header {
      font-family: $font-family-heading;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 44px;
      text-align: center;
      color: rgba(255, 255, 255, 0.88);
      margin-bottom: 40px; }

    .cards-row {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      margin: 0 130px;

      @media (max-width: 600px) {
        margin: 0;
        flex-direction: column;
        padding: 0 20px; } }

    .card {
      max-width: 32.5%;
      flex: 0.33;
      background: linear-gradient(180deg, #2D2D2D 0%, #1C1C1C 100%);
      border-radius: 16px;
      padding: 40px;
      color: white;

      @media (max-width: 600px) {
        max-width: 100%;
        flex: 1;
        margin-bottom: 24px; }

      .feature-img {
        margin-bottom: 40px; }

      .card-title {
        font-family: $font-family-heading;
        font-size: 32px;
        line-height: 40px;
        font-weight: bold;
        margin-bottom: 16px;
        min-height: 80px;
        color: white;

        @media (max-width: 600px) {
          height: auto; } }

      .explain {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 4px;
        color: #FFFFFF88;
        min-height: 60px;

        @media (max-width: 600px) {
          height: auto; } }

      .learn-more {
        font-size: 16px;
        font-weight: 300;
        color: #FF7F57; }

      .call-to-action {
        display: block;
        margin: 32px auto 0 0; } } }

  #FAQ {
    margin: 0 130px;

    @media (max-width: 600px) {
      margin: 0;
      flex-direction: column;
      padding: 0 20px; }

    .header {
      font-family: $font-family-heading;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 44px;
      text-align: center;
      color: rgba(255, 255, 255, 0.88);
      margin-bottom: 40px; } } }

